import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";
import lozad from "lozad";


// Connects to data-controller="carousel-multiple-items"
export default class extends Controller {
  static targets = ["note"];
  connect() {
    this.setImagesPerSlide();
    const observer = lozad();
    observer.observe();
  }

  async handleSelect(event) {
    event.preventDefault();
    const url = event.target.href;
    const request = new FetchRequest("get", url, {
      responseKind: "turbo-stream",
    });
    const response = await request.perform();
    if (response.ok) {
      // $('#spinner-for-submit').fadeOut('slow');
      this.setImagesPerSlide();
      console.log('batched')
    }
  }

  setImagesPerSlide() {
    // console.log(".......................")
    let items = this.noteTargets
    // let items = document.querySelectorAll('.gallery .carousel .carousel-item')

    items.forEach((el) => {
      const minPerSlide = 3
      let next = el.nextElementSibling
      for (var i = 1; i < minPerSlide; i++) {
        if (!next) {
          // wrap carousel by using first child
          next = items[0]
        }
        let cloneChild = next.cloneNode(true)
        el.appendChild(cloneChild.children[0])
        next = next.nextElementSibling
      }
      // console.log(next)
    })
  }
}
