import { Controller } from "@hotwired/stimulus"
import autosize from 'autosize';

// Connects to data-controller="comment"
export default class extends Controller {
  static targets = [ "form", 'content']

  connect() {
    autosize(document.querySelectorAll('textarea.form-control'));

    let placeholder = '댓글을 입력하세요.'
    let submitHotkey = 'Ctrl+Enter'
    if (navigator.platform.startsWith("Mac")) {
      submitHotkey = '⌘+Enter'
    }
    if (isMobile()) {
      this.contentTarget.placeholder = placeholder
    } else {
      this.contentTarget.placeholder = `${placeholder} 줄바꿈은 Enter키, 저장은 ${submitHotkey}키를 누르세요.`
    }
    this.contentTarget.addEventListener('keydown', async (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
        const formData = new FormData(this.formTarget);
        const response = await fetch(this.formTarget.action, {
          headers: {
            "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
          },
          method: this.formTarget.method,
          body: formData,
        });
        if (response.ok) {
          // Turbo.visit(response.url);
        }
        // this.formTarget.submit()
        this.formTarget.reset()
      }
    });
  }
}

function isMobile() {
  return /iPhone|Android|iPad|iPod/i.test(navigator.userAgent);
}
