import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="schedule-time"
export default class extends Controller {
  static targets = [ 'allDay', 'startTime', 'endTime', 'endTimeGroup', 'startTimeLabel']

  connect() {
    // console.log("schedule-time controller connected", this.element)
    const _this = this;

    // Add an event listener to the modal
    this.element.addEventListener('shown.bs.modal', function() {
      // console.log(_this.allDayTarget.checked)
      // if(_this.allDayTarget.checked){
      //   _this.startTimeLabelTarget.textContent = "일시"
      //   _this.endTimeGroupTarget.style.display = 'none'
      // } else {
      //   _this.startTimeLabelTarget.textContent = "시작시간"
      //   _this.endTimeGroupTarget.style.display = 'block'
      // }
    })

    this.startTimeTarget.addEventListener('change', function() {
      // Get the value of the start time input and parse it as a Date object
      const startTime = new Date(this.value);
      console.log(_this.allDayTarget.checked)

      // Calculate the end time by adding 1 hour to the start time
      // const endTime = new Date(startTime.getTime() + (60 * 60 * 1000));
      const endTime = new Date(startTime.setHours(startTime.getHours() + 1));

      // Format the end time as a string in ISO 8601 format (YYYY-MM-DDTHH:MM:SS)
      const adjustedEndTime = new Date(endTime.setHours(endTime.getHours() + 9));

      // Set the value of the end time input to the calculated end time string
      _this.endTimeTarget.value = adjustedEndTime.toISOString().slice(0, 16);

    });
    // this.toggleEndTime()
  }

  // toggleEndTime(){
  //   if(this.allDayTarget.checked){
  //     this.startTimeLabelTarget.textContent = "일시"
  //     this.endTimeGroupTarget.style.display = 'none'
  //   } else {
  //     this.startTimeLabelTarget.textContent = "시작시간"
  //     this.endTimeGroupTarget.style.display = 'block'
  //   }
  // }
}
