import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="infinite-scroll"
export default class extends Controller {
  static targets = ["entries", "pagination", 'isLoading']

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore()
      }
    })
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) {
      this.paginationTarget.innerHTML = `
        <div class='text-center'>
          <div class='mb-1 text-secondary'>- 마지막 페이지 -</div>
          <div class='mb-4'><a href='#'><i class="fa-solid fa-circle-chevron-up"></i> 상단으로 이동</a></div>
        </div>
      `
      return
    }
    let url = next_page.href

    this.isLoadingTarget.classList.remove('d-none')
    this.paginationTarget.classList.add('d-none')
    fetch(url)
      .then(response => response.text())
      .then(html => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, "text/html")
        this.entriesTarget.insertAdjacentHTML("beforeend", doc.querySelector("#entries").innerHTML)
        this.paginationTarget.innerHTML = doc.querySelector("#pagination").innerHTML
        this.isLoadingTarget.classList.add('d-none')
        this.paginationTarget.classList.remove('d-none')
      })
  }
}
