import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

// Connects to data-controller="popup"
export default class extends Controller {
  initialize() {
    super.initialize();
    const options = {
      backdrop: 'static',
    };
    this.popup = new bootstrap.Modal(this.element, options);
  }

  connect() {
    // 저장된 체크박스 상태 가져오기
    const isPopupAllowed = localStorage.getItem("popupAllowed");

    if (!isPopupAllowed) {
      // 팝업창을 여는 코드 작성
      this.popup.show();
    } else {
      let expiration = localStorage.getItem("popupExpiration");
      if (expiration && new Date() > new Date(expiration)) {
        localStorage.removeItem("popupAllowed");
        localStorage.removeItem("popupExpiration");
      }
    }

    // 모달이 닫힐 때 실행되는 함수
    this.element.addEventListener("hidden.bs.modal", (_event) => {
      // 체크박스 요소 가져오기
      const noMorePopupCheckbox = this.element.querySelector("#noMorePopup");

      // 체크박스 상태 저장
      if (noMorePopupCheckbox.checked) {
        localStorage.setItem("popupAllowed", "true");
        let expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7); // 현재로부터 7일 후
        localStorage.setItem("popupExpiration", expirationDate.toISOString());
      }
    });
  }

  handleCloseModal(event) {
    // this.popup.hide();
  }
}
