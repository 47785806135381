import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="document"
export default class extends Controller {
  static values = {
    id: Number,
    noteId: Number
  }

  connect() {
    // console.log("Document controller connected")
  }

  handleClickDeleteButton(e){
    e.preventDefault();
    let text = "첨부파일을 삭제하시겠습니까?";
    if (confirm(text) == true) {
      // console.log("delete button clicked", checked_ids)

      /** Purge images from server **/
      this.element.style.display = 'none';
      fetch(`/documents/${this.idValue}/notes/${this.noteIdValue}`, {
        headers: {
          "Content-Type": "text/vnd.turbo-stream.html",
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
        },
        method: "DELETE"
      }).then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }
}
