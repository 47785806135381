import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs"; // 이 줄을 수정했습니다.

// Connects to data-controller="sortable-banners"
export default class extends Controller {
  connect() {
    console.log("Sortable Banners controller connected");
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      filter: ".banner-immovable", // 고정된 요소를 필터링합니다.
      onStart: this.checkFixed.bind(this),
      onMove: this.checkDestination.bind(this),
      onEnd: this.updatePositions.bind(this), // 이 줄을 추가합니다.
    });
    console.log("Sortable instance created:", this.sortable);
    this.addFixedBannerStyles();
    this.initialPositions = this.getBannerPositions();
  }

  checkFixed(event) {
    const item = event.item;
    console.log("Dragged item:", item);
    console.log("Fixed attribute (raw):", item.dataset.fixed);
    const isFixed = item.classList.contains("banner-immovable");
    console.log("Is fixed (parsed):", isFixed);
    if (isFixed) {
      event.preventDefault();
      alert("고정된 배너는 드래그할 수 없습니다.");
    }
  }

  checkDestination(event, originalEvent) {
    const targetElement = event.related;
    const isTargetFixed = targetElement.classList.contains("banner-immovable");
    if (isTargetFixed) {
      return false; // 고정된 배너 위치로의 이동을 막습니다.
    }
  }

  updatePositions(event) {
    console.log("Updated item:", event.item);
    const isFixed = event.item.dataset.fixed === "true";
    console.log("Fixed attribute:", isFixed);
    if (isFixed) {
      this.revertPositions();
      return;
    }

    const newPositions = this.getBannerPositions();
    const changedBanner = event.item.getAttribute("data-banner-id");
    const newPosition = newPositions.indexOf(changedBanner) + 1;

    fetch("/admin/banners/update_positions", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({ positions: newPositions }), // 여기를 확인하세요
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log("배너 위치가 업데이트되었습니다.");
          this.updateBannerNumbers();
          this.initialPositions = newPositions;
        } else {
          console.error("배너 위치 업데이트에 실패했습니다:", data.errors);
          this.revertPositions();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.revertPositions();
      });
  }

  getBannerPositions() {
    return Array.from(this.element.children).map((child) =>
      child.getAttribute("data-banner-id")
    );
  }

  updateBannerNumbers() {
    this.element
      .querySelectorAll("[data-banner-id]")
      .forEach((banner, index) => {
        const bannerNumberElement = banner.querySelector(".banner-number");
        if (bannerNumberElement) {
          bannerNumberElement.textContent = `배너 ${index + 1}`;
        } else {
          console.warn(
            `Banner ${index + 1} does not have a .banner-number element`
          );
        }
      });
  }

  revertPositions() {
    const originalOrder = Array.from(this.element.children).sort(
      (a, b) =>
        parseInt(a.getAttribute("data-original-index")) -
        parseInt(b.getAttribute("data-original-index"))
    );
    originalOrder.forEach((item) => this.element.appendChild(item));
  }

  addFixedBannerStyles() {
    this.element.querySelectorAll('[data-fixed="true"]').forEach((row) => {
      row.style.backgroundColor = "#f8f9fa";
      row.title = "고정된 배너입니다.";
    });
  }
}
