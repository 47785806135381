import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="two-weeks"
export default class extends Controller {
  static targets = [ "fixedToTopUntil" ]

  handleTwoWeeks(e) {
    e.preventDefault();
    const twoWeeksLater = this.addWeeksToDate(new Date(), 2).toISOString();
    console.log(twoWeeksLater.slice(0, 16));
    this.fixedToTopUntilTarget.value = twoWeeksLater.slice(0, 16);
  }

  handleReset(e) {
    e.preventDefault();
    this.fixedToTopUntilTarget.value = "";
  }

  addWeeksToDate(dateObj, numberOfWeeks) {
    dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7);
    console.log(dateObj);
    return dateObj;
  }
}
