import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="preview-upload"
export default class extends Controller {
  static targets = [ "input", "preview", "previewLabel", "panel" ]

  connect() {
    this.inputTarget.addEventListener("change", this.previewUpload.bind(this))
    if(this.previewTarget.src == '#') {
      this.previewTarget.style.display = "inline-block"
    } else {
      this.previewTarget.style.display = "none"
    }
  }

  previewUpload() {
    const [file] = this.inputTarget.files
    if (file) {
      this.previewTarget.src = URL.createObjectURL(file)
      this.previewTarget.style.display = "inline-block"
      this.previewLabelTarget.innerText = '업로드할 사진'
      this.panelTarget.classList.remove('d-none')
      this.previewTarget.classList.add('animate__animated', 'animate__bounceIn');
    }
  }
}
