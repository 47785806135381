import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
let timeoutId;

// Connects to data-controller="find-me"
export default class extends Controller {
  static targets = ["searchSQL", "searchDb", "searchName", "searchGc", "searchEmail", "searchAccountId"]

  connect(){
    this.element.addEventListener("keydown", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });

  }

  handleSearch(event) {
    event.keyCode === 13 && event.preventDefault()
    const loading = event.target.nextElementSibling
    loading.classList.remove('d-none')
    // console.log(loading)
    clearTimeout(timeoutId)
    const url = `search_me`
    timeoutId = setTimeout(async () => {
      const request = new FetchRequest("post", url, {
        responseKind: "turbo-stream",
        body: {
          db_name: this.searchDbTarget.value,
          name: this.searchNameTarget.value,
          gc: this.searchGcTarget.value,
          email: this.searchEmailTarget.value,
          account_id: this.searchAccountIdTarget.value
        }
      })
      const response = await request.perform()
      if (response.ok) {
        // console.log(response.text)
        const text = response.text
        const results = document.getElementById("results")
        results.innerText = text
        // const searchSQL = document.getElementById("search-sql")
        // searchSQL.innerText = url
      }
      event.target.nextElementSibling.classList.add('d-none')
    }, 1000);
  }
}
