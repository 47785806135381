import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-hangul"
export default class extends Controller {
  connect() {
    // console.log("check-hangul-controller connected...")
  }

  handleKeyup(e) {
    const regexp = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:"'\\]/g;
    let value = e.target.value;
    if (regexp.test(value)) {
      e.target.value = value.replace(regexp, '');
    }
  }
}
