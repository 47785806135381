import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    // console.log("Flash controller connected")
    this.flashModal = new bootstrap.Modal(
      document.getElementById("flashModal"),
      {}
    );
    this.flashModal.show();
  }

  disconnect() {
    // console.log("Flash controller disconnected")
    this.flashModal.dispose();
  }
}
