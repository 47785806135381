import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="users"
export default class extends Controller {
  static targets = ['user', 'checkAllChecked'];
  static values = {
    page: Number,
  }

  connect() {
    // console.log("Users Controller connected...", this.element);
  }

  checkAll(){
    // e.preventDefault()
    const allUserTargets = this.userTargets
    // console.log(this.userAllCheckedTarget.checked)
    allUserTargets.forEach(user => {
      if(!user.disabled){
        user.checked = !user.checked
      }
    })
  }

  async acceptAllAsOffice(e) {
    e.preventDefault();

    const userChecks = this.userTargets.filter(user => user.checked)
    const ids = userChecks.map(user => user.value).join(',')
    const url = '/admin/users/accept_all_as_office_toggle'
    const page = this.pageValue;
    // console.log(ids, url, page);

    if (userChecks.length === 0) {
      alert("사용자를 선택한 후 계속하세요.");
    } else {
      if (confirm(`사무장권한지정 작업을 계속하시겠습니까?`) === true) {
        const request = new FetchRequest("post", url, {
          body: JSON.stringify({
            ids: ids,
            page: page,
          }),
          responseKind: "turbo-stream",
        });
        const response = await request.perform();
        if (response.ok) {
          // window.location.replace(`/admin/users`);
          history.replaceState(null, null, `/admin/users?page=${page}`);
          // $('#spinner-for-submit').fadeOut('slow');
        }
      }
    }
  }

  async acceptAllAsCommittee(e) {
    e.preventDefault();

    const userChecks = this.userTargets.filter(user => user.checked)
    const ids = userChecks.map(user => user.value).join(',')
    const url = '/admin/users/accept_all_as_committee_toggle'
    const page = this.pageValue;
    // console.log(ids, url, page);

    if(userChecks.length === 0) {
      alert("사용자를 선택한 후 계속하세요.")
    } else {
      if (confirm(`임원권한지정 작업을 계속하시겠습니까?`) === true) {
        const request = new FetchRequest('post', url, {
          body: JSON.stringify({
            ids: ids,
            page: window.location.href.split('page=')[1]
          }),
          responseKind: "turbo-stream"
        })
        const response = await request.perform()
        if (response.ok) {
          history.replaceState(null, null, `/admin/users?page=${page}`);
          // $('#spinner-for-submit').fadeOut('slow');
        }
      }
    }
  }

  async acceptAllAsPending(e) {
    e.preventDefault();

    const userChecks = this.userTargets.filter(user => user.checked)
    const ids = userChecks.map(user => user.value).join(',')
    const url = '/admin/users/accept_all_as_pending'
    const page = this.pageValue;
    // console.log(ids, url, page);

    if(userChecks.length === 0) {
      alert("사용자를 선택한 후 계속하세요.")
    } else {
      if (confirm(`모든권한 정지작업을 계속하시겠습니까?`) === true) {
        const request = new FetchRequest('post', url, {
          body: JSON.stringify({
            ids: ids,
            page: window.location.href.split('page=')[1]
          }),
          responseKind: "turbo-stream"
        })
        const response = await request.perform()
        if (response.ok) {
          history.replaceState(null, null, `/admin/users?page=${page}`);
          // $('#spinner-for-submit').fadeOut('slow');
        }
      }
    }
  }

  async acceptAll(e) {
    e.preventDefault();

    const userChecks = this.userTargets.filter(user => user.checked)
    const ids = userChecks.map(user => user.value).join(',')
    const url = '/admin/users/accept_all_toggle'
    const page = this.pageValue;
    // console.log(ids, url, page);

    if(userChecks.length === 0) {
      alert("사용자를 선택한 후 계속하세요.")
    } else {
      if (confirm(`회원인증 작업을 계속하시겠습니까?`) === true) {
        const request = new FetchRequest('post', url, {
          body: JSON.stringify({
            ids: ids,
            page: window.location.href.split('page=')[1]
          }),
          responseKind: "turbo-stream"
        })
        const response = await request.perform()
        if (response.ok) {
          history.replaceState(null, null, `/admin/users?page=${page}`);
          // $('#spinner-for-submit').fadeOut('slow');
        }
      }
    }
  }

  async resetPassword(e) {
    e.preventDefault();

    const userChecks = this.userTargets.filter(user => user.checked)
    const ids = userChecks.map(user => user.value).join(',')
    const url = '/admin/users/reset_password'
    const page = this.pageValue;
    // console.log(ids, url, page);

    if (userChecks.length === 0) {
      alert("사용자를 선택한 후 계속하세요.")
    } else {
      if (confirm(`비밀번호 초기화 작업을 계속하시겠습니까?`) === true) {
        const request = new FetchRequest('post', url, {
          body: JSON.stringify({
            ids: ids,
            page: window.location.href.split('page=')[1]
          }),
          responseKind: "turbo-stream"
        })
        const response = await request.perform()
        if (response.ok) {
          history.replaceState(null, null, `/admin/users?page=${page}`);
          // $('#spinner-for-submit').fadeOut('slow');
        }
      }
    }
  }

  async confirmAll(e) {
    e.preventDefault();

    const userChecks = this.userTargets.filter(user => user.checked)
    const ids = userChecks.map(user => user.value).join(',')
    const url = '/admin/users/confirm_all_toggle'
    const page = this.pageValue;
    // console.log(ids, url, page);

    if(userChecks.length === 0) {
      alert("사용자를 선택한 후 계속하세요.")
    } else {
      if (confirm(`이메일인증 작업을 계속하시겠습니까?`) === true) {
        const request = new FetchRequest('post', url, {
          body: JSON.stringify({
            ids: ids,
            page: window.location.href.split('page=')[1]
          }),
          responseKind: "turbo-stream"
        })
        const response = await request.perform()
        if (response.ok) {
          history.replaceState(null, null, `/admin/users?page=${page}`);
          // $('#spinner-for-submit').fadeOut('slow');
        }
      }
    }
  }

}
