import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from '@fullcalendar/multimonth'
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import koLocale from "@fullcalendar/core/locales/ko";
import * as bootstrap from 'bootstrap';
import moment from 'moment';

// Connects to data-controller="fullcalendar"
export default class extends Controller {
  static targets = ["calendar", "modal", "startTime", "endTime", "allDay", "endTimeGroup", "startTimeLabel"];

  initialize(){
    this.bootstrapModal = new bootstrap.Modal(document.getElementById("calendarModal"), {});
  }

  connect() {
    let _this = this;
    let calendar = new Calendar(this.calendarTarget, {
      themeSystem: 'bootstrap5',
      events: "/calendar.json",
      // timeZone: "Asia/Seoul",
      stateSaving: true,
      locale: koLocale,
      selectable: true,
      editable: true,
      dayMaxEventRows: true,
      eventLimitClick: "popover",
      navLinks: true,
      initialView: 'listYear',
      headerToolbar: {
        center: "multiMonthGrid,multiMonthStack multiMonthYear,dayGridMonth,listYear",
        right: "today prev,next"
      },
      views: {
        multiMonthGrid: {
          type: 'dayGridYear', // multiYear 뷰를 사용하여 grid 형식으로 보여줍니다.
          // multiMonthMaxColumns: 1,
          duration: { years: 3 }, // 보여줄 기간을 3년으로 설정합니다.
          buttonText: '연속형' // 버튼에 표시될 텍스트를 설정합니다.
        },
        multiMonthStack: {
          type: 'multiMonthYear', // multiYear 뷰를 사용하여 stack 형식으로 보여줍니다.
          multiMonthMaxColumns: 1,
          duration: { years: 3 }, // 보여줄 기간을 3년으로 설정합니다.
          buttonText: '스택형' // 버튼에 표시될 텍스트를 설정합니다.
        }
      },
      plugins: [bootstrap5Plugin, listPlugin, multiMonthPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin],
      navLinkDayClick: function (date, jsEvent) {
        const adjustedDate = new Date(date.setHours(date.getHours() + 9));
        const ISODate = adjustedDate.toISOString().substring(0, 16);
        _this.bootstrapModal.show();
        _this.startTimeTarget.value = ISODate;
        _this.endTimeTarget.value = ISODate;
        // if(_this.allDayTarget.checked){
        //   _this.startTimeLabelTarget.textContent = "일시"
        //   _this.endTimeGroupTarget.style.display = 'none'
        // } else {
        //   _this.startTimeLabelTarget.textContent = "시작시간"
        //   _this.endTimeGroupTarget.style.display = 'block'
        // }
      },
      select: function(info) {
        let startDate = info.startStr;
        let endDate = moment(info.endStr).subtract(1, 'days').format('YYYY-MM-DD');
        console.log(startDate, endDate)

        // console.log(info)
        // const adjustedStartTime = new Date(info.start.setHours(info.start.getHours() + 9));
        // const adjustedEndTime = new Date(info.end.setHours(info.end.getHours() + 9));

        const adjustedStartTime = new Date(startDate);
        const adjustedEndTime = new Date(endDate);

        console.log(adjustedStartTime, adjustedEndTime)
        //
        _this.bootstrapModal.show();
        _this.allDayTarget.checked = true;
        _this.startTimeTarget.value = adjustedStartTime.toISOString().substring(0, 16);
        _this.endTimeTarget.value = adjustedEndTime.toISOString().substring(0, 16);


        // Clear the selection
        calendar.unselect();
        // console.log($(calendar))
      },
      eventClick: function (info) {
        info.jsEvent.preventDefault();
        Turbo.visit(info.event.extendedProps.show_url);
      },
      eventDrop: function (info) {
        // console.log(info)
        const csrfToken = document.querySelector("[name='csrf-token']").content
        let data = _this.data(info);
        fetch(info.event.url, {
          method: 'PATCH',
          headers: {
            "X-CSRF-Token": csrfToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => console.log(response))
          .catch(error => console.error('fetch에 문제가 있었습니다.', error))

        // Rails.ajax({
        //   type: "PUT",
        //   url: info.event.url,
        //   data: new URLSearchParams(data).toString(),
        // });
      },
      eventResize: function (info) {
        const csrfToken = document.querySelector("[name='csrf-token']").content
        let data = _this.data(info);
        fetch(info.event.url, {
          method: 'PATCH',
          headers: {
            "X-CSRF-Token": csrfToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
          .then(response => console.log(response))
          .catch(error => console.error('fetch에 문제가 있었습니다.', error))
        // Rails.ajax({
        //   type: "PUT",
        //   url: info.event.url,
        //   data: new URLSearchParams(data).toString(),
        // });
      },
    });
    calendar.render();
  }

  data(info) {
    return {
      "note": {
        "start_time": info.event.start,
        "end_time": info.event.end
      }
    };
  }
}
