import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modify-comments"
export default class extends Controller {
  static targets = ["modifyLinks"]
  static values = {
    commentableType: String,
    commentableId: Number,
    commentId: Number,
    commenterId: Number
  }

  connect() {
    // console.log(this.commentIdValue, this.commenterIdValue);
    let user_id = document.getElementById(`${this.commentableTypeValue}_${this.commentableIdValue}_comments`).dataset.userId;
    let hide_modify_links = user_id != this.commenterIdValue
    if (hide_modify_links) {
      this.modifyLinksTarget.style.display = "none";
    }
  }
}
