import { Controller } from "@hotwired/stimulus"
import '../add_jquery'
import "blueimp-gallery/js/blueimp-gallery"
import "blueimp-gallery/js/blueimp-gallery-fullscreen"
import "blueimp-gallery/js/blueimp-gallery-indicator"
import "blueimp-gallery/js/blueimp-gallery-video"
import "blueimp-gallery/js/blueimp-gallery-vimeo"
import "blueimp-gallery/js/blueimp-gallery-youtube"
import "blueimp-gallery/js/blueimp-helper"
import "blueimp-gallery/js/jquery.blueimp-gallery"

// Connects to data-controller="gallery"
export default class extends Controller {
  connect() {
    document.getElementById('links').onclick = function (event) {
      event = event || window.event
      var target = event.target || event.srcElement
      var link = target.src ? target.parentNode : target
      var options = { index: link, event: event }
      var links = this.getElementsByTagName('a')
      blueimp.Gallery(links, options)
    }
  }
}
