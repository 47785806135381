import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="thumbnails"
export default class extends Controller {
  connect() {
  }

  checkAllToggle(event) {
    let checkboxes = document.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = event.target.checked
    })
  }
}
