import tinymce from 'tinymce/tinymce'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  initialize() {
    this.defaults = {
      base_url: "/assets/tinymce",
      plugins:
        "preview importcss contextmenu autolink autosave save code visualblocks visualchars fullscreen image link media table charmap nonbreaking advlist lists emoticons help charmap wordcount",
      menubar: false,
      toolbar:
        "blocks fontsize formatting | emoticons charmap | fontsizeselect formatselect | format table alignments outdent indent | numlist bullist | forecolor backcolor removeformat | insertfile image media link | wordcount code fullscreen",
      toolbar_groups: {
        alignments: {
          icon: "align-left",
          tooltip: "Alignments",
          items: "alignleft aligncenter alignright alignjustify",
        },
        formatting: {
          icon: "format",
          tooltip: "Formatting",
          items:
            "bold italic underline strikethrough | superscript subscript | removeformat",
        },
      },
      toolbar_sticky: true,
      init_instance_callback: function (editor) {
        $(editor.getContainer())
          .find("button.tox-statusbar__wordcount")
          .click(); // if you use jQuery
      },
      images_upload_url: "/tinymce_uploader/image",
      file_picker_types: "file image media",
      suffix: ".min",
      relative_urls: false,
      language_url: "/tinymce/langs/ko_KR.js",
      language: "ko_KR",
      // content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default')
      // skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide-dark' : 'oxide'),
      content_style: "body { font-size:16px }",
      contextmenu: false,
      // contextmenu: "copy paste | link image inserttable | cell row column deletetable",
    };
  }

  connect() {


    // Initialize the app
    let config = Object.assign({ target: this.inputTarget }, this.defaults)
    tinymce.init(config)
      .then()
  }

  disconnect() {
    tinymce.remove()
  }
}
