import { Controller } from "@hotwired/stimulus";
import "../add_jquery";
import "blueimp-gallery/js/blueimp-gallery";
import "blueimp-gallery/js/blueimp-gallery-fullscreen";
import "blueimp-gallery/js/blueimp-gallery-indicator";
import "blueimp-gallery/js/blueimp-gallery-video";
import "blueimp-gallery/js/blueimp-gallery-vimeo";
import "blueimp-gallery/js/blueimp-gallery-youtube";
import "blueimp-gallery/js/blueimp-helper";
import "blueimp-gallery/js/jquery.blueimp-gallery";

// Connects to data-controller="gallery"
export default class extends Controller {
  static targets = ["photo", "checkbox", "deleteButton"];
  initialize() {
    super.initialize();
    document.getElementById("links").onclick = function (event) {
      event = event || window.event;
      var target = event.target || event.srcElement;
      var link = target.src ? target.parentNode : target;
      var options = { index: link, event: event };
      var links = this.getElementsByTagName("a");
      blueimp.Gallery(links, options);
    };
  }

  connect() {
    // console.log("Lightbox Image Gallery controller connected.");
    if(document.querySelector('.loading-photos')) {
      document.querySelector('.loading-photos').classList.remove('d-none');
      document.querySelector('.loading-photos').classList.add('animate__animated', 'animate__fadeIn', 'animate__slow');
    }

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.addEventListener("click", this.handleClickCheckbox, true);
    });

  }

  handleClickCheckbox(e){
    e.stopPropagation();
    // console.log("checkbox clicked", e.target.value)
  }

  handleClickDeleteButton(e){
    e.preventDefault();
    let checkeds = this.checkboxTargets.filter((checkbox) => checkbox.checked);
    let checked_ids = checkeds.map((checkbox) => checkbox.value);
    let text = "선택한 사진을 삭제하시겠습니까?";
    if (checkeds.length > 0 && confirm(text) == true) {
      // console.log("delete button clicked", checked_ids)

      /** Purge images from server **/
      checkeds.forEach((checkbox) => checkbox.parentNode.style.display = 'none');
      fetch(`/photos/${checked_ids}`, {
        headers: {
          "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
        },
        method: "DELETE"
      }).then((response) => {
        if (response.ok) {
          checked_ids.forEach((id) => {
            document.getElementById(`attachment_${id}`).remove();
          });
          document.querySelector('#check_all_photos').checked = false;
          // console.log(`${checked_ids.length} photos deleted.`);
        }
      });
    } else {
      alert("삭제할 사진을 선택해주세요.");
    }
  }
}
